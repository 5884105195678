<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <h2>Правила 2</h2>
            </div>
          </div>

          <div class="row mt-32">
            <div class="col">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consequatur dicta dolor eos esse exercitationem laudantium neque, non placeat qui quia quibusdam quidem quo tempora ut vel veritatis vitae?</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consequatur dicta dolor eos esse exercitationem laudantium neque, non placeat qui quia quibusdam quidem quo tempora ut vel veritatis vitae?</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consequatur dicta dolor eos esse exercitationem laudantium neque, non placeat qui quia quibusdam quidem quo tempora ut vel veritatis vitae?</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At autem consequatur dicta dolor eos esse exercitationem laudantium neque, non placeat qui quia quibusdam quidem quo tempora ut vel veritatis vitae?</p>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button>
                Принимаю правила и подтверждаю участие в финале.
              </v-button>
            </div>

            <div class="col-auto">
              <v-button
                color="danger"
                @click="modal.isShow = true"
              >
                Отказываюсь от участия
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <v-modal
      v-if="modal.isShow"
      @close="modal.isShow = false"
    >
      <h4 slot="header">Отказ от участия</h4>

      <p>
        Подтвердите, что Вы отказываетесь от участия в финале
      </p>

      <div class="row">
        <div class="col">
          <v-field
            :error="!!modal.textarea.error"
          >
            <label>{{ modal.textarea.label }}</label>
            <v-textarea
              v-model="modal.textarea.value"
              :placeholder="modal.textarea.placeholder"
              required
              autogrow
            ></v-textarea>
            <span class="v-field__error">{{ modal.textarea.error }}</span>
          </v-field>
        </div>
      </div>

      <div class="row" slot="footer">
        <div class="col-auto">
          <v-button @click="modal.isShow = false">
            Подтвердить отказ
          </v-button>
        </div>

        <div class="col">
          <v-button
            color="danger"
            @click="modal.isShow = false"
          >
            Назад
          </v-button>
        </div>
      </div>

    </v-modal>
  </div>
</template>

<script>
import { VButton, VCard, VModal, VField, VTextarea } from '@components/base'

export default {
  name: 'SAgreement2',

  components: {
    VTextarea,
    VField,
    VModal,
    VButton,
    VCard
  },

  data () {
    return {
      modal: {
        isShow: false,
        textarea: {
          label: 'Опишите причину отказа',
          value: '',
          error: ''
        }
      }
    }
  },

  methods: {
    onClick () {
      console.log('Ответ')
    }
  }
}
</script>
